import React from 'react';
import {
  trackCtaClicked,
  CTA_TYPES,
} from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';

import { updateAddressDetails, createAddressDetails } from '~/libs/client/GuestCollection';
import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';

// interface
import type { WPublicGuestCollectionDetailView } from '@zola/svc-web-api-ts-client';
import type { AddressFormValues, Guest } from '~/components/ContactCollectorGuestView/@types/form';

// components
import { MapPinIcon } from '@zola/zola-ui/src/components/SvgIconsV3/MapPin';
import { MailIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Mail';
import { DeviceMobileIcon } from '@zola/zola-ui/src/components/SvgIconsV3/DeviceMobile';

// styles
import {
  StepContainer,
  FormHeader,
} from '~/components/ContactCollectorGuestView/ContactCollectorGuestView.styles';
import {
  DislaimerTxt,
  GuestInfoSbsContainer,
  GuestNames,
  IconContainer,
  StyledBtn,
  StyledLnk,
} from './ConfirmationForm.styles';

interface ConfirmationFormProps {
  formValues: AddressFormValues;
  weddingDetails: WPublicGuestCollectionDetailView;
  onSubmit: (isFinal: boolean) => void;
}

const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
  formValues,
  weddingDetails,
  onSubmit,
}) => {
  const createRequest = () => {
    // create final request object
    const request = {
      wedding_account_uuid: weddingDetails.wedding_account_uuid,
      city: formValues.city,
      country_code: formValues.country,
      postal_code: formValues.zip,
      state_province: formValues.state,
      tier: 'A',
      guests: formValues.guests,
      mobile_phone: formValues.mobile,
      email_address: formValues.email,
      guest_group_uuid: formValues.guest_group_uuid,
    };
    if (formValues.affiliation) {
      createAddressDetails({
        ...request,
        affiliation: formValues.affiliation,
        address_1: formValues.street,
        address_2: formValues.apt,
      }).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error creating guest collection details');
      });
    } else {
      updateAddressDetails(formValues.guest_group_uuid, {
        ...request,
        // TODO: change address fields to snake_case after end point is updated
        address1: formValues.street,
        address2: formValues.apt,
      }).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error updating guest address details');
      });
    }
  };

  const renderGuestNames = (guests: [Guest]) => {
    const capitalize = (s: string) => {
      return s.charAt(0).toUpperCase() + s.toLocaleLowerCase().slice(1);
    };
    return guests
      .filter(guest => {
        return guest.first_name || guest.family_name || guest.name_unknown;
      })
      .map(guest => {
        if (guest.name_unknown) {
          return 'Guest';
        }
        return `${guest.prefix ? `${capitalize(guest.prefix)}. ` : ''}${capitalizeFirst(
          guest.first_name
        )} ${capitalizeFirst(guest.family_name)}${
          guest.suffix ? ` ${capitalize(guest.suffix)}` : ''
        }`;
      })
      .join(' & ');
  };

  const handleSubmit = () => {
    createRequest();
    trackCtaClicked({
      business_unit: 'WEDDINGS',
      cta_type: CTA_TYPES.BUTTON,
      group_id: 'GUEST_COLLECTION_FORM',
      cta_id: 'guest-collection-submit-address',
      position: 1,
      location: 'GUEST_COLLECTION_FORM',
      section: 'REVIEW_SCREEN',
      cta: null,
    });
    onSubmit(true);
  };

  const handleEdit = () => {
    onSubmit(false);
  };

  return (
    <StepContainer>
      <FormHeader>Does this look right?</FormHeader>
      <section>
        <article>
          <GuestNames>{renderGuestNames(formValues.guests)}</GuestNames>
          <GuestInfoSbsContainer>
            <IconContainer>
              <MapPinIcon height={24} width={24} />
            </IconContainer>
            <div>
              <p>
                {`${formValues.street} `}
                {formValues.apt}
              </p>
              <p>{`${formValues.city}, ${formValues.state}, ${formValues.zip}`}</p>
            </div>
          </GuestInfoSbsContainer>
        </article>
        <article>
          <GuestInfoSbsContainer>
            <IconContainer>
              <MailIcon height={24} width={24} />
            </IconContainer>
            <div>
              <p>{formValues.email ? formValues.email : ''}</p>
            </div>
          </GuestInfoSbsContainer>
          <GuestInfoSbsContainer>
            <IconContainer>
              <DeviceMobileIcon height={24} width={24} />
            </IconContainer>
            <div>
              <p>{formValues.mobile ? formValues.mobile : ''}</p>
            </div>
          </GuestInfoSbsContainer>
        </article>
      </section>
      <DislaimerTxt>
        Your info will only be shared with{' '}
        {capitalizeFirst(weddingDetails.owner_first_name as string)} &{' '}
        {capitalizeFirst(weddingDetails.partner_first_name as string)}
      </DislaimerTxt>
      <section>
        <StyledBtn
          type="submit"
          onClick={handleSubmit}
          size="large"
          mobileSize="large"
          textTransform={false}
        >
          Yes, looks great
        </StyledBtn>
        <StyledLnk role="button" onClick={handleEdit}>
          No, I need to edit
        </StyledLnk>
      </section>
    </StepContainer>
  );
};

export default ConfirmationForm;
