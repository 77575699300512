import React, { useState } from 'react';

import { CollectionSteps } from '~/pages/addr/[id]';
import { AddressFormValues } from '~/components/ContactCollectorGuestView/@types/form';

// types
import type {
  WPublicGuestCollectionDetailView,
  WCollectAddressGuestGroupGuestMatchView,
} from '@zola/svc-web-api-ts-client';

// components
import GuestSearchForm from '~/components/ContactCollectorGuestView/components/GuestSearchForm';
import ContactInfoForm from '~/components/ContactCollectorGuestView/components/ContactInfoForm';
import ConfirmationForm from '~/components/ContactCollectorGuestView/components/ConfirmationForm';
import CompletionPage from '~/components/ContactCollectorGuestView/components/CompletionPage';

// styles
/**
 * Note: these styled components are based on our 3.0 Onboarding componets from zola-ui
 * https://style-guide.zola.com/latest/index.html?path=/docs/components-onboard-3-0-onboardcontainer--onboard-container
 */
import {
  Container,
  Content,
  ContentContainer,
  CouplePhoto,
  SectionsContainer,
  SectionsContent,
  Spacer,
} from './ContactCollectorGuestView.styles';

type ContactCollectorGuestViewProps = {
  formValues?: AddressFormValues;
  guestDetail: WCollectAddressGuestGroupGuestMatchView | undefined | null;
  handleAddressCollection: (result: AddressFormValues) => void;
  handleConfirmation: (isConfirmed: boolean) => void;
  handleGuestSearch: (result: WCollectAddressGuestGroupGuestMatchView | null) => void;
  step: number;
  weddingDetails: WPublicGuestCollectionDetailView;
};

const ContactCollectorGuestView = ({
  formValues,
  guestDetail,
  handleAddressCollection,
  handleConfirmation,
  handleGuestSearch,
  step,
  weddingDetails,
}: ContactCollectorGuestViewProps): JSX.Element => {
  const [mobileShowSearchForm, setMobileShowSearchForm] = useState(false);

  const hasCouplePhoto = Boolean(weddingDetails.image_link);

  const renderContactCollectorStep = (): JSX.Element => {
    switch (step) {
      case CollectionSteps.CollectionStep:
        return (
          <ContactInfoForm
            formValues={formValues}
            guestDetail={guestDetail}
            onSubmit={handleAddressCollection}
          />
        );
      case CollectionSteps.ConfirmationStep:
        if (formValues) {
          return (
            <ConfirmationForm
              formValues={formValues}
              onSubmit={handleConfirmation}
              weddingDetails={weddingDetails}
            />
          );
        }
        break;
      case CollectionSteps.CompletionStep:
        if (weddingDetails) {
          return (
            <CompletionPage
              guestName={formValues?.guests[0].first_name}
              weddingDetails={weddingDetails}
            />
          );
        }
        break;
      case CollectionSteps.SearchStep:
      default:
        return (
          <GuestSearchForm
            onSubmit={handleGuestSearch}
            weddingDetails={weddingDetails}
            mobileShowSearchForm={mobileShowSearchForm}
            setMobileShowSearchForm={setMobileShowSearchForm}
          />
        );
    }
    return <></>;
  };

  return (
    <>
      <Spacer />
      <Container data-testid="ContactCollectorGuestView">
        <ContentContainer step={step} mobileShowSearchForm={mobileShowSearchForm}>
          <Content hasCouplePhoto={hasCouplePhoto}>
            {hasCouplePhoto && (
              <CouplePhoto src={weddingDetails.image_link} alt="Couple contact collector" />
            )}
          </Content>
        </ContentContainer>
        <SectionsContainer>
          <SectionsContent>{renderContactCollectorStep()}</SectionsContent>
        </SectionsContainer>
      </Container>
    </>
  );
};

export default ContactCollectorGuestView;
