import React, { useState, useReducer, useEffect } from 'react';
import { NextRouter, useRouter } from 'next/router';
import Head from 'next/head';

// components
import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';
import ContactCollectorGuestView from '~/components/ContactCollectorGuestView';

// utils
import { getCollectionDetails } from '~/libs/server/GuestCollection';
import staticFlags, { SUPERLINK_PUBLIC_QUERY_PARAM } from '~/libs/universal/utils/staticFlags';

// interfaces
import { GetServerSideProps } from 'next';
import type {
  WPublicGuestCollectionDetailView,
  WCollectAddressGuestGroupGuestMatchView,
} from '@zola/svc-web-api-ts-client';
import { AddressFormValues } from '~/components/ContactCollectorGuestView/@types/form';

// styles
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import styles from './AddressCollection.module.less';

export enum CollectionSteps {
  SearchStep,
  CollectionStep,
  ConfirmationStep,
  CompletionStep,
}

enum FlowActions {
  Forward = 'Forward',
  Backward = 'Backward',
}

interface AddressCollectionProps {
  weddingDetails: WPublicGuestCollectionDetailView | null;
  superlinkPublicQueryParamEnabled?: boolean;
}

const initialState = {
  step: CollectionSteps.SearchStep,
};

function reducer(state: { step: CollectionSteps }, action: { type: FlowActions }) {
  switch (action.type) {
    case FlowActions.Forward:
      return { step: state.step + 1 };
    case FlowActions.Backward:
      return { step: state.step - 1 };
    default:
      throw new Error();
  }
}

function getPageTitle(weddingDetails: WPublicGuestCollectionDetailView) {
  if (weddingDetails.link_title) {
    return weddingDetails.link_title;
  }
  return `${weddingDetails.owner_first_name} & ${weddingDetails.partner_first_name} request your address and info.`;
}

const renderZolaLogo = (): JSX.Element => {
  return <ZolaLogo className={styles.logoRebrand} color={COLORS3.BLACK_100} />;
};

const checkForViewportQuery = (router: NextRouter, updateStateFunc: (val: boolean) => void) => {
  if (router.isReady) {
    const { viewport } = router.query;
    if (typeof viewport === 'string' && viewport.toUpperCase() === 'DESKTOP') {
      updateStateFunc(true);
    }
  }
};

const AddressCollection = ({
  weddingDetails,
  superlinkPublicQueryParamEnabled,
}: AddressCollectionProps): JSX.Element | null => {
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [guestDetail, setGuestDetail] = useState<
    WCollectAddressGuestGroupGuestMatchView | undefined | null
  >();
  const [formValues, setFormValues] = useState<AddressFormValues | undefined>();
  const [shouldSetViewport, setShouldSetViewport] = useState<boolean>(false);

  useEffect(() => {
    checkForViewportQuery(router, setShouldSetViewport);
  }, [router, setShouldSetViewport, router.isReady]);

  function handleGuestSearch(result: WCollectAddressGuestGroupGuestMatchView | null) {
    setGuestDetail(result);
    dispatch({ type: FlowActions.Forward });
  }

  function handleAddressCollection(result: AddressFormValues) {
    setFormValues(result);
    dispatch({ type: FlowActions.Forward });
  }

  function handleConfirmation(isConfirmed: boolean) {
    dispatch({
      type: isConfirmed ? FlowActions.Forward : FlowActions.Backward,
    });
  }

  const weddingDate =
    weddingDetails?.wedding_date &&
    new Date(weddingDetails.wedding_date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'UTC',
    });
  let pageTitle;
  if (weddingDetails) {
    pageTitle = getPageTitle(weddingDetails);
  }
  const fullTitle = `${pageTitle} ${weddingDate || ''}`;

  return weddingDetails ? (
    <div>
      <Head>
        <title>{fullTitle}</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={fullTitle} />
        {weddingDetails.image_link && (
          <meta property="og:image" content={weddingDetails.image_link} />
        )}
        {!weddingDetails.image_link && (
          <meta
            property="og:video"
            content="https://zola-web-assets.s3.amazonaws.com/static-assets/videos/super-link/super-link-animation-rebrand.mp4"
          />
        )}
        <meta property="og:site_name" content="zola.com" />
        <meta name="twitter:site" content="@zola" />
        <meta name="twitter:card" content="summary" />
        {superlinkPublicQueryParamEnabled && shouldSetViewport && (
          <meta name="viewport" content="width=1200" />
        )}
      </Head>
      <nav className={styles.nav}>{renderZolaLogo()}</nav>
      <ContactCollectorGuestView
        handleGuestSearch={handleGuestSearch}
        step={state.step}
        weddingDetails={weddingDetails}
        formValues={formValues}
        guestDetail={guestDetail}
        handleAddressCollection={handleAddressCollection}
        handleConfirmation={handleConfirmation}
      />
    </div>
  ) : null;
};

export const getServerSideProps: GetServerSideProps = async context => {
  const { id } = context.query;
  const weddingDetails = await getCollectionDetails(id as string);
  const superlinkPublicQueryParamEnabled = staticFlags.get(SUPERLINK_PUBLIC_QUERY_PARAM);

  if (!weddingDetails) {
    return {
      // returns the default 404 page with a status code of 404
      notFound: true,
    };
  }
  return {
    props: {
      weddingDetails,
      superlinkPublicQueryParamEnabled,
    },
  };
};

export default AddressCollection;
