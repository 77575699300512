import React from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { InputFieldV2Adapter } from '@zola/zola-ui/src/components/Form/InputFieldV2';
import CheckboxFieldAdapter from '@zola/zola-ui/src/components/Form/CheckboxField/CheckboxFieldAdapter';
import DropdownV2Adapter from '@zola/zola-ui/src/components/Form/Dropdown/DropdownV2Adapter';
// validator
import { composeValidators, Validations } from '@zola/zola-ui/src/components/Form/util';

import States from '~/constants/USStates';
import Countries from '~/constants/Countries';
import NamePrefix from '~/constants/NamePrefix';

// interface
import type {
  WCollectAddressGuestGroupGuestMatchView,
  WCollectAddressGuestView,
} from '@zola/svc-web-api-ts-client';
import type { AddressFormValues } from '~/components/ContactCollectorGuestView/@types/form';

// styles
import {
  StepContainer,
  FormHeader,
} from '~/components/ContactCollectorGuestView/ContactCollectorGuestView.styles';
import {
  StyledReviewBtn,
  GuestContainer,
  InnerLabel,
  UnknownGuestContainer,
  UnknownCheckboxContainer,
  AddressContainer,
  CountryContainer,
  CityStateZipContainer,
  EmailMobileContainer,
  OuterLabel,
  StyledReviewBtnContainer,
} from './ContactInfoForm.styles';

const { ZIP, EMAIL, PHONE, REQUIRED, maxLength: MAX_LENGTH } = Validations;

interface ContactInfoFormProps {
  guestDetail: WCollectAddressGuestGroupGuestMatchView | null | undefined;
  formValues?: AddressFormValues;
  onSubmit: (values: AddressFormValues) => void;
}

// for sorting guests by relationship_type:
const sortByRelationshipType = (
  guest1: WCollectAddressGuestView,
  guest2: WCollectAddressGuestView
) => {
  const rank = { CHILD: 2, PARTNER: 1, PRIMARY: 0 };
  // disable the check since svc-ts-client doesn't return the actual enum value to make the check pass
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return rank[guest1.relationship_type] - rank[guest2.relationship_type];
};

const ContactInfoForm: React.FC<ContactInfoFormProps> = ({ guestDetail, formValues, onSubmit }) => {
  const handleFormSubmit = (values: AddressFormValues) => {
    return onSubmit(values);
  };

  let initialValues;
  if (formValues) {
    initialValues = formValues;
  } else if (!formValues && guestDetail && guestDetail.guests) {
    const guests = guestDetail.guests.sort(sortByRelationshipType).map(({ ...rest }) => ({
      source: 'COLLECT_ADDRESS',
      guest_id: rest.id,
      ...rest,
    }));
    initialValues = {
      country: 'US',
      guests,
      guest_group_uuid: guestDetail.guest_group_uuid,
    };
  } else if (!formValues && !guestDetail) {
    const guests = [
      {
        first_name: undefined,
        family_name: undefined,
        relationship_type: 'PRIMARY',
        source: 'COLLECT_ADDRESS',
      },
    ];
    initialValues = {
      country: 'US',
      guests,
      affiliation: 'UNKNOWN',
    };
  }

  return (
    <StepContainer data-testid="ContactInfoForm">
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        mutators={{
          setUnknownGuest: (fields, state, utils) => {
            fields.forEach((field: string) => {
              utils.changeValue(state, field, () => undefined);
            });
          },
          ...arrayMutators,
        }}
        render={({ form, handleSubmit, values, invalid }) => (
          <form onSubmit={handleSubmit}>
            <FormHeader>Please add your info below</FormHeader>
            <FieldArray name="guests">
              {({ fields }) =>
                fields.map((name, index) => {
                  const disabled = fields.value[index].name_unknown === true;
                  return (
                    <div key={name}>
                      {disabled && (
                        <UnknownGuestContainer>
                          <Field<string>
                            component={InputFieldV2Adapter}
                            name={`${name}.guest_name`}
                            defaultValue="Guest"
                            type="text"
                            label="Name"
                            allowChangeToEmpty
                            disabled={disabled}
                          />
                        </UnknownGuestContainer>
                      )}
                      {!disabled && (
                        <GuestContainer>
                          <Field<string>
                            component={DropdownV2Adapter}
                            name={`${name}.prefix`}
                            label={
                              <OuterLabel>
                                Title <InnerLabel>(opt.)</InnerLabel>
                              </OuterLabel>
                            }
                            placeholder="Select"
                            disabled={disabled}
                            options={NamePrefix}
                          />
                          <Field<string>
                            component={InputFieldV2Adapter}
                            name={`${name}.first_name`}
                            type="text"
                            label="First name"
                            allowChangeToEmpty
                            disabled={disabled}
                            validate={composeValidators<string>(REQUIRED, MAX_LENGTH(50))}
                          />
                          <Field<string>
                            component={InputFieldV2Adapter}
                            name={`${name}.family_name`}
                            type="text"
                            label="Last name"
                            allowChangeToEmpty
                            disabled={disabled}
                            validate={composeValidators<string>(REQUIRED, MAX_LENGTH(50))}
                          />
                          <Field<string>
                            component={InputFieldV2Adapter}
                            name={`${name}.suffix`}
                            type="text"
                            label={
                              <OuterLabel>
                                Suffix <InnerLabel>(opt.)</InnerLabel>
                              </OuterLabel>
                            }
                            allowChangeToEmpty
                            disabled={disabled}
                            validate={MAX_LENGTH(20)}
                          />
                        </GuestContainer>
                      )}
                      {index !== 0 && (
                        <UnknownCheckboxContainer>
                          <Field<boolean>
                            component={CheckboxFieldAdapter}
                            name={`${name}.name_unknown`}
                            label="Guest name unknown"
                            id={`${name}-guest-name-unknown`}
                            type="checkbox"
                            onClick={() => {
                              form.mutators.setUnknownGuest(
                                `${name}.first_name`,
                                `${name}.family_name`,
                                `${name}.suffix`
                              );
                            }}
                          />
                        </UnknownCheckboxContainer>
                      )}
                    </div>
                  );
                })
              }
            </FieldArray>
            <div>
              <AddressContainer>
                <Field<string>
                  component={InputFieldV2Adapter}
                  name="street"
                  type="text"
                  label="Street address"
                  validate={composeValidators<string>(REQUIRED, MAX_LENGTH(100))}
                />
                <Field<string>
                  component={InputFieldV2Adapter}
                  name="apt"
                  type="text"
                  label={
                    <OuterLabel>
                      Apt/floor <InnerLabel>(opt.)</InnerLabel>
                    </OuterLabel>
                  }
                  validate={MAX_LENGTH(100)}
                />
              </AddressContainer>
              <CountryContainer>
                <Field<string>
                  component={DropdownV2Adapter}
                  name="country"
                  options={Countries}
                  label="Country"
                  validate={REQUIRED}
                />
              </CountryContainer>
              <CityStateZipContainer>
                <Field<string>
                  component={InputFieldV2Adapter}
                  name="city"
                  type="text"
                  label="City"
                  validate={composeValidators<string>(REQUIRED, MAX_LENGTH(35))}
                />
                {values.country === 'US' && (
                  <>
                    <Field<string>
                      component={DropdownV2Adapter}
                      name="state"
                      placeholder="Select"
                      options={States}
                      label="State/region"
                      validate={REQUIRED}
                    />
                    <Field<string>
                      component={InputFieldV2Adapter}
                      name="zip"
                      type="text"
                      label="Zip code"
                      validate={composeValidators<string>(REQUIRED, ZIP)}
                    />
                  </>
                )}
                {values.country !== 'US' && (
                  <>
                    <Field<string>
                      component={InputFieldV2Adapter}
                      name="state"
                      type="text"
                      label="State/region"
                      validate={REQUIRED}
                    />
                    <Field<string>
                      component={InputFieldV2Adapter}
                      name="zip"
                      type="text"
                      label="Postal code"
                      validate={composeValidators<string>(REQUIRED, MAX_LENGTH(15))}
                    />
                  </>
                )}
              </CityStateZipContainer>
            </div>
            <div>
              <EmailMobileContainer>
                <Field<string>
                  component={InputFieldV2Adapter}
                  name="email"
                  type="email"
                  label="Email"
                  validate={composeValidators<string>(REQUIRED, EMAIL)}
                />
                {values.country !== 'US' && (
                  <Field<string>
                    component={InputFieldV2Adapter}
                    name="mobile"
                    type="tel"
                    label="Mobile"
                    validate={composeValidators<string>(REQUIRED, MAX_LENGTH(25))}
                  />
                )}
                {values.country === 'US' && (
                  <Field<string>
                    component={InputFieldV2Adapter}
                    name="mobile"
                    type="tel"
                    label="Mobile"
                    validate={composeValidators<string>(REQUIRED, PHONE, MAX_LENGTH(25))}
                  />
                )}
              </EmailMobileContainer>
            </div>
            <StyledReviewBtnContainer>
              <StyledReviewBtn
                type="submit"
                disabled={invalid}
                size="large"
                mobileSize="large"
                textTransform={false}
              >
                Review
              </StyledReviewBtn>
            </StyledReviewBtnContainer>
          </form>
        )}
      />
    </StepContainer>
  );
};

export default ContactInfoForm;
